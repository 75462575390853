import React, { useEffect, useState } from 'react';
import './App.css';
import { MainPage } from './components/MainPage/MainPage';
import { PageMobile } from './components/PageMobile/PageMobile';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1300);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1300);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className='App'>
      <img src='/img/logo.svg' className='App-logo' alt='logo' />
      {isMobile ? <PageMobile /> : <MainPage />}
      {isMobile ? (
        <div className='footer_mobile'>
          <span>
            Ao clicar no botão "Registar", concorda com os termos da promoção de
            bónus e com as regras da casa de apostas.
          </span>
          <div className='block_mobile'>
            <span>2007 - 2024 © «Fairpari».</span>
            <img
              src='/img/limitation.svg'
              className='limitation'
              alt='limitation'
            />
          </div>
        </div>
      ) : (
        <div className='footer'>
          <span>2007 - 2024 © «Fairpari».</span>
          <span>
            Ao clicar no botão "Registar", concorda com os termos da promoção de
            bónus e com as regras da casa de apostas.
          </span>
          <img
            src='/img/limitation.svg'
            className='limitation'
            alt='limitation'
          />
        </div>
      )}
    </div>
  );
}

export default App;
