import { Timer } from '../Timer/Timer';
import './PageMobile.css';
import React from 'react';

export const PageMobile = () => {
  return (
    <div className='mobile_container'>
      <div className='content_block_mobile'>
        <div className='image_block_mobile'>
          <img
            src='/img/smokeMobile.png'
            className='smokeBg_mobile'
            alt='logo'
          />
          <img src='/img/fly.png' className='fly_mobile' alt='logo' />
        </div>
        <Timer />
        <h1 className='title_mobile'>
          Para o depósito ao recarregar o seu saldo
        </h1>
        <p className='percent_mobile'>Receba um bônus de até</p>
        <div className='block_cash'>
          <span className='text_mobile'>1626 USD</span>
          <span className='text_desc_mobile'>+ 150 FS</span>
        </div>
      </div>
      <a
        className='App-link_mobile'
        href='/'
        target='_blank'
        rel='noopener noreferrer'
      >
        Receba um bônus
      </a>
    </div>
  );
};
