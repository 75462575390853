import { Timer } from '../Timer/Timer';
import './MainPage.css';
import React from 'react';

export const MainPage = () => {
  return (
    <div className='main_container'>
      <div className='content_block'>
        <Timer />
        <h1 className='title'>Para o depósito ao recarregar o seu saldo</h1>
        <p className='percent'>Receba um bônus de até </p>
        <span className='text'>1626 USD</span>
        <span className='text_desc'>+ 150 FS</span>
        <a
          className='App-link'
          href='/'
          target='_blank'
          rel='noopener noreferrer'
        >
          Receba um bônus
        </a>
      </div>
      <div className='image_block'>
        <img src='/img/smokeBg.png' className='smokeBg' alt='logo' />
        <img src='/img/fly.png' className='fly' alt='logo' />
      </div>
    </div>
  );
};
