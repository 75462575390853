import React, { useState, useEffect } from 'react';
import './Timer.css';

export const Timer = () => {
  const [hours, setHours] = useState(23);
  const [minutes, setMinutes] = useState(59);
  const [seconds, setSeconds] = useState(59);
  const [over, setOver] = useState(false);

  useEffect(() => {
    let timerID = null;

    const tick = () => {
      if (over) return;

      if (hours === 0 && minutes === 0 && seconds === 0) {
        setOver(true);
      } else if (minutes === 0 && seconds === 0) {
        setHours(hours - 1);
        setMinutes(59);
        setSeconds(59);
      } else if (seconds === 0) {
        setMinutes(minutes - 1);
        setSeconds(59);
      } else {
        setSeconds(seconds - 1);
      }
    };

    timerID = setInterval(tick, 1000);

    return () => clearInterval(timerID);
  }, [hours, minutes, seconds, over]);

  const dots =  <div class="vertical-dots">
  <div className='dot'></div>
  <div className='dot'></div>
</div>

  return (
    <div className='timer_container'>
      <p>
        {hours.toString().padStart(2, '0')}h
      </p> 
      <span>{dots}</span>
      <p>
        {minutes.toString().padStart(2, '0')}m
      </p> 
      <span>{dots}</span>
      <p>
        {seconds.toString().padStart(2, '0')}s
      </p>
    </div>
  );
};

